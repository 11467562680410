import store from "../../store";
import router from "../../router";
import SettingsService from "../../services/SettingsService";

export default {
  deleteUser(userId) {
    store.dispatch("setLoading", true);
    const response = SettingsService.deleteUsersById(userId)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  createUser(payload) {
    store.dispatch("setLoading", true);
    const response = SettingsService.createUsers(payload)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  editUser(payload) {
    store.dispatch("setLoading", true);
    const response = SettingsService.editUsers(payload)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  fetchUser(payload) {
    store.dispatch("setLoading", true);
    const response = SettingsService.fetchUser(payload)
      .then((result) => {
        const { status, user } = result.data
        if (status) {
          return { status: true, user };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
};
