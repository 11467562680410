<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <form @submit.prevent="createUser()">
          <div class="card-header">
            <h4>Create User</h4>
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="fields.name" placeholder="Christopher Okokon"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Email</label>
                  <input type="email" class="form-control" v-model="fields.email" placeholder="christopher.okokon@asolarnig.com"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Phone number</label>
                  <input type="text" class="form-control" v-model="fields.phone" placeholder="0818 000 0000"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Category</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.category">
                      <option value="" disabled>Select an option</option>
                      <option value="employee">Employee</option>
                      <option value="admin">Admin</option>
                      <option value="agent">Agent</option>
                    </select>
                    <small>
                      <p class="mb-0">* Password will be sent to the email address entered above</p>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right pt-0">
            <button class="btn btn-primary" :class="{ 'btn-progress': loading }">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SettingsHelpers from "@/functions/actions/SettingsRequest";
export default {
  name: "createUser",
  computed: {
    ...mapState(["loading"])
  },
  mounted() {},
  data() {
    return {
      fields: {
        category: ""
      },
    };
  },
  methods: {
    async createUser() {
      if (this.validateData()) {
        const response = await SettingsHelpers.createUser(this.fields);
        if (response.status) {
          this.showAlert("Success", "User has been created successfully", "success")
          this.$router.push({
            name: "All Users"
          })
        } else {
          this.showAlert(
            "Error occured",
            `${response.message}. Kindly reload the page`,
            "error"
          );
        }
      }
    },
    validateData() {
      if (this.fields.name) {
        if (this.fields.email) {
          if (this.fields.phone) {
            if (this.fields.category) {
              return true
            } else {
              this.showAlert(
                "Validation error",
                "Select a user category",
                "warning"
              );
            }
          } else {
            this.showAlert(
              "Validation error",
              "Provide a phone number",
              "warning"
            );
          }
        } else {
          this.showAlert(
            "Validation error",
            "Provide an email address",
            "warning"
          );
        }
      } else {
        this.showAlert(
          "Validation error",
          "Provide the user\'s name",
          "warning"
        );
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style lang="">
</style>