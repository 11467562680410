import Api from "@/services/Api";

export default {
  deleteUsersById(userId) {
    return Api().delete(`/admin/users/${userId}`);
  },
  createUsers(payload) {
    return Api().post(`/admin/users`, payload);
  },
  editUsers(payload) {
    return Api().put(`/admin/users`, payload);
  },
  fetchUser(payload) {
    return Api().get(`/admin/users/${payload}`);
  }
};